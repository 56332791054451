import baseRoutes from '../../route/baseRoutes';
import systemRoutes from '../../route/systemRoutes';
import custRoutes from '../../route/custRoutes';
import walletRoutes from '../../route/walletRoutes';
import ordersRoutes from '../../route/ordersRoutes';
import financingRoutes from '../../route/financingRoutes';
import mchRoutes from '../../route/mchRoutes';
import nbiRoutes from '../../route/nbiRoutes';
import cockpitRoutes from '../../route/cockpitRoutes';
import extraRoutes from '../../route/extraRoutes';
import notFoundRoutes from '../../route/notFoundRoute';

export default [
    ...baseRoutes,
    ...systemRoutes,
    ...custRoutes,
    ...walletRoutes,
    ...ordersRoutes,
    ...financingRoutes,
    ...mchRoutes,
    ...nbiRoutes,
    ...cockpitRoutes,
    ...extraRoutes,
    ...notFoundRoutes,
]
