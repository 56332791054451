<template>
  <div id="header">
    <div class="title" v-on:click="toPage('/home', '首页', 'home')">
      首页
    </div>
    <div class="middle">
      <a-menu theme="dark" :style="{ lineHeight: '56px' }" v-model="index_key" mode="horizontal">
        <!--        &lt;!&ndash; 首页 &ndash;&gt;-->
        <!--        <a-menu-item key="/home" @click="toPage('/home', '首页', 'home')">-->
        <!--          <i class="iconfont">&#xe652;</i>-->
        <!--          首页-->
        <!--        </a-menu-item>-->
        <!-- 融资管理 -->
        <a-sub-menu v-if="oneChange('/Financingmanagement')">
          <span slot="title" class="submenu-title-wrapper">
            <a-icon type="wallet"/>授信管理
          </span>
          <a-menu-item v-if="twoChange('/Financingmanagement/loan')" key="/Financingmanagement/loan"
                       @click="toPage('/Financingmanagement/loan', '贷款申请', 'loanPage')">
            <a-icon type="reconciliation"/>
            贷款申请
          </a-menu-item>
          <a-menu-item v-if="twoChange('/Financingmanagement/Financingwhitelist')"
                       @click="toPage('/Financingmanagement/Financingwhitelist', '白名单', 'Financingwhitelist')"
                       key="/Financingmanagement/Financingwhitelist">
            <a-icon type="user"/>
            白名单
          </a-menu-item>
          <a-menu-item v-if="twoChange('/Financingmanagement/GuarantorManage')"
                       @click="toPage('/Financingmanagement/GuarantorManage', '担保人', 'GuarantorManage')"
                       key="/Financingmanagement/GuarantorManage">
            <a-icon type="usergroup-add"/>
            担保人
          </a-menu-item>
        </a-sub-menu>
        <a-sub-menu>
          <span slot="title" class="submenu-title-wrapper">
            <a-icon type="money-collect"/>支付管理
          </span>
          <a-menu-item v-if="twoChange('/orders/PaymentOrders')" key="/orders/PaymentOrders"
                       @click="toPage('/orders/PaymentOrders', '订单支付', 'PaymentOrders')">
            <a-icon type="transaction"/>
            订单支付
          </a-menu-item>
          <a-menu-item v-if="twoChange('/orders/PaymentProtocolManage')" key="/orders/PaymentProtocolManage"
                       @click="toPage('/orders/PaymentProtocolManage', '支付协议', 'PaymentProtocolManage')">
            <a-icon type="contacts"/>
            支付协议
          </a-menu-item>
          <a-menu-item v-if="twoChange('/orders/payWithhold')" key="/orders/payWithhold"
                       @click="toPage('/orders/payWithhold', ' 归集协议', 'payWithhold')">
            <a-icon type="deployment-unit"/>
            归集协议
          </a-menu-item>
        </a-sub-menu>
        <!-- 订单管理 -->
        <a-sub-menu v-if="oneChange('/orders')">
          <span slot="title" class="submenu-title-wrapper">
            <a-icon type="shopping-cart"/>订单管理
          </span>
          <a-menu-item v-if="twoChange('/orders/Orderentry')" key="/orders/Orderentry"
                       @click="toPage('/orders/Orderentry', '订单导入', 'orderEntryPage')">
            <a-icon type="inbox"/>
            订单导入
          </a-menu-item>
          <a-menu-item v-if="twoChange('/orders/OrderDraft')" key="/orders/OrderDraft"
                       @click="toPage('/orders/OrderDraft', '订单录入', 'orderDraft')">
            <a-icon type="exception"/>
            订单录入
          </a-menu-item>
          <a-menu-item v-if="twoChange('/orders/Originalordermanagement')" key="/orders/Originalordermanagement"
                       @click="toPage('/orders/Originalordermanagement', '原始订单', 'originalOrderPage')">
            <a-icon type="shopping-cart"/>
            原始订单
          </a-menu-item>
          <a-menu-item v-if="twoChange('/orders/Summaryorder')" key="/orders/Summaryorder"
                       @click="toPage('/orders/Summaryorder', '汇总订单', 'summaryOrderPage')">
            <a-icon type="table"/>
            汇总订单
          </a-menu-item>
        </a-sub-menu>

        <!-- 客户管理 -->
        <a-sub-menu v-if="oneChange('/cust')">
          <span slot="title" class="submenu-title-wrapper">
            <a-icon type="user"/>客户管理
          </span>
          <a-menu-item v-if="twoChange('/cust/personage')" key="/cust/personage"
                       @click="toPage('/cust/personage', '个人客户', 'personCustPage')">
            <i class="iconfont">&#xe699;</i> 个人客户
          </a-menu-item>
          <a-menu-item v-if="twoChange('/cust/enterprise')" key="/cust/enterprise"
                       @click="toPage('/cust/enterprise', '企业客户', 'enterprisePage')">
            <a-icon type="bank"/>
            企业客户
          </a-menu-item>
        </a-sub-menu>


        <!-- 钱包管理 -->
        <a-sub-menu v-if="oneChange('/wallet')">
          <span slot="title" class="submenu-title-wrapper">
            <a-icon type="wallet"/>钱包管理
          </span>
          <!--  v-if="twoChange('wallet/accountmanagement')"-->
          <!--          <a-menu-item v-if="twoChange('/wallet/accountmanagement')" key="/wallet/accountmanagement"-->
          <!--            @click="toPage('/wallet/accountmanagement', '账户管理', 'walletPage')">-->
          <!--            <a-icon type="unordered-list" />账户管理-->
          <!--          </a-menu-item>-->
          <!--          <a-menu-item>-->
          <!--            <i class="iconfont">&#xe699;</i>银行卡管理-->
          <!--          </a-menu-item>-->
          <a-menu-item v-if="twoChange('/wallet/accountlist')" key="/wallet/accountlist"
                       @click="toPage('/wallet/accountlist', '账户列表', 'accountList')">
            <a-icon type="credit-card"/>
            账户列表
          </a-menu-item>
          <a-menu-item key="/wallet/settleAccounts" v-if="twoChange('/wallet/settleAccounts')"
                       @click="toPage('/wallet/settleAccounts', '待清算户', 'SettleAccounts')">
            <a-icon type="percentage"/>
            待清算户
          </a-menu-item>
          <a-menu-item v-if="twoChange('/wallet/ClearAgreementManage')" key="/wallet/ClearAgreementManage"
                       @click="toPage('/wallet/ClearAgreementManage', '清分协议', 'ClearAgreementManage')">
            <a-icon type="fork"/>
            清分协议
          </a-menu-item>
        </a-sub-menu>
        <!-- 电商中心 -->
        <a-sub-menu v-if="oneChange('/Mch')">
          <span slot="title" class="submenu-title-wrapper">
             <a-icon type="aliwangwang"/>商户中心
          </span>
          <a-menu-item v-if="twoChange('/Mch/MchManage')" key="/Mch/MchManage"
                       @click="toPage('/Mch/MchManage', '商户管理', 'MchManage')">
            <a-icon type="aliwangwang"/>
            商户管理
          </a-menu-item>
          <a-menu-item v-if="twoChange('/Mch/MchShopManage')" key="/Mch/MchShopManage"
                       @click="toPage('/Mch/MchShopManage', '店铺管理', 'MchShopManage')">
            <a-icon type="taobao-circle"/>
            店铺管理
          </a-menu-item>
          <a-menu-item v-if="twoChange('/Mch/MchShopInvoice')" key="/Mch/MchShopInvoice"
                       @click="toPage('/Mch/MchShopInvoice', '发票管理', 'MchShopInvoice')">
            <a-icon type="file"/>
            发票管理
          </a-menu-item>
          <a-menu-item v-if="twoChange('/Mch/MchWithDrawal')" key="/Mch/MchWithDrawal"
                       @click="toPage('/Mch/MchWithDrawal', '提现记录', 'MchWithdrawManage')">
            <a-icon type="interaction"/>
            提现记录
          </a-menu-item>
          <!-- 暂时还未配置权限 -->
          <a-menu-item v-if="twoChange('/Mch/CheckList')" key="/Mch/CheckList"
                       @click="toPage('/Mch/CheckList', '待审批事项', 'CheckList')">
            <a-icon type="audit"/>
            待审批事项
          </a-menu-item>
        </a-sub-menu>


        <!-- 电商中心 -->
        <a-sub-menu v-if="oneChange('/nbi')">
          <span slot="title" class="submenu-title-wrapper">
             <a-icon type="money-collect"/>非银专区
          </span>
          <a-menu-item v-if="twoChange('/nbi/lender')" key="/nbi/lender"
                       @click="toPage('/nbi/lender', '出资人', 'lender')">
            <a-icon type="insurance"/>
            出资人
          </a-menu-item>
          <a-menu-item v-if="twoChange('/nbi/borrower')" key="/nbi/borrower"
                       @click="toPage('/nbi/borrower', '融资人', 'borrower')">
            <a-icon type="user"/>
            融资人
          </a-menu-item>
          <a-menu-item v-if="twoChange('/nbi/advance')" key="/nbi/advance"
                       @click="toPage('/nbi/advance', '垫资', 'advance')">
            <a-icon type="transaction"/>
            垫资
          </a-menu-item>
          <a-menu-item v-if="twoChange('/nbi/reclaim')" key="/nbi/reclaim"
                       @click="toPage('/nbi/reclaim', '清算', 'reclaim')">
            <a-icon type="fork"/>
            清算
          </a-menu-item>
          <a-menu-item v-if="twoChange('/nbi/cg')" key="/nbi/cg"
                       @click="toPage('/nbi/cg', 'cg', 'cg')">
            <a-icon type="fork"/>
            CG
          </a-menu-item>

        </a-sub-menu>
        <!-- !基础管理 -->
        <a-sub-menu v-if="oneChange('/base')">
          <span slot="title" class="submenu-title-wrapper">
            <i class="iconfont">&#xe66f;</i>基础配置
          </span>
          <a-menu-item v-if="twoChange('/base/configparam')" key="/base/configparam"
                       @click="toPage('/base/configparam', '参数配置', 'configParamPage')">
            <i class="iconfont">&#xe699;</i>参数配置
          </a-menu-item>
          <a-menu-item key="/base/loanproduct" @click="toPage('/base/loanproduct', '贷款产品', 'loanproduct')">
            <a-icon type="transaction"/>
            贷款产品
          </a-menu-item>
          <a-menu-item key="/base/salesplatform" @click="toPage('/base/salesplatform', '销售平台', 'Salesplatform')">
            <a-icon type="reconciliation"/>
            销售平台
          </a-menu-item>
          <a-menu-item key="/base/Servicechargeallocation" v-if="twoChange('/base/Servicechargeallocation')"
                       @click="toPage('/base/Servicechargeallocation', '服务费', 'FeeRulePage')">
            <a-icon type="appstore"/>
            服务费
          </a-menu-item>
          <a-menu-item v-if="twoChange('/base/coreenterprise')" key="/base/coreenterprise"
                       @click="toPage('/base/coreenterprise', '核心企业', 'Coreenterprise')">
            <a-icon type="appstore"/>
            核心企业
          </a-menu-item>
          <a-menu-item key="/base/smstemplate" v-if="twoChange('/base/smstemplate')"
                       @click="toPage('/base/smstemplate', '短信模版', 'smstemplatePage')">
            <a-icon type="container"/>
            短信模版
          </a-menu-item>
          <a-menu-item key="/base/filestoragemode" v-if="twoChange('/base/filestoragemode')"
                       @click="toPage('/base/filestoragemode', '文件存储', 'Filestoragemode')">
            <a-icon type="file-add"/>
            文件存储
          </a-menu-item>

        </a-sub-menu>

        <!--?系统管理 -->
        <a-sub-menu v-if="oneChange('/system')">
          <span slot="title" class="submenu-title-wrapper">
            <i class="iconfont">&#xe615;</i>系统管理
          </span>
          <a-menu-item v-if="twoChange('/system/user')" key="/system/user"
                       @click="toPage('/system/user', '用户管理', 'userPage')">
            <i class="iconfont">&#xe699;</i>用户管理
          </a-menu-item>
          <a-menu-item v-if="twoChange('/system/role')" key="/system/role"
                       @click="toPage('/system/role', '角色管理', 'rolePage')">
            <i class="iconfont">&#xe618;</i>角色管理
          </a-menu-item>
          <a-menu-item v-if="twoChange('/system/authority')" key="/system/authority"
                       @click="toPage('/system/authority', '权限管理', 'authorityPage')">
            <i class="iconfont">&#xe637;</i>权限管理
          </a-menu-item>
          <a-menu-item v-if="twoChange('/system/organization')" key="/system/organization"
                       @click="toPage('/system/organization', '机构管理', 'organizationPage')">
            <i class="iconfont">&#xe611;</i>机构管理
          </a-menu-item>
          <a-menu-item key="/system/filestorageinformation" v-if="twoChange('/system/filestorageinformation')"
                       @click="toPage('/system/filestorageinformation', '文件存储信息', 'Filestorageinformation')">
            <a-icon type="file"/>
            文件存储信息
          </a-menu-item>
          <a-menu-item key="/system/shortmessagerecord" v-if="twoChange('/system/shortmessagerecord')"
                       @click="toPage('/system/shortmessagerecord', '短信记录', 'Shortmessagerecord')">
            <a-icon type="mail"/>
            短信记录
          </a-menu-item>

          <a-menu-item v-if="twoChange('/system/log')" key="/system/log"
                       @click="toPage('/system/log', '日志管理', 'logPage')">
            <i class="iconfont">&#xe604;</i>日志管理
          </a-menu-item>

          <a-menu-item key="/system/qrcode" v-if="twoChange('/system/qrcode')"
                       @click="toPage('/system/qrcode', '二维码业务', 'qrCodePage')">
            <i class="iconfont">&#xe604;</i>二维码业务
          </a-menu-item>
        </a-sub-menu>
        <!--?系统管理 -->
        <a-sub-menu v-if="oneChange('/Cockpit')">
          <span slot="title" class="submenu-title-wrapper">
            <a-icon type="pie-chart"/>驾驶舱
          </span>
          <a-menu-item key="/cockpit" v-if="twoChange('/Cockpit/cockpit')"
                       @click="toPage('/cockpit', '业务报表', 'Cockpit')">
            <a-icon type="bar-chart"/>
            业务报表
          </a-menu-item>
          <a-menu-item key="/cockpit/coreCompany" v-if="twoChange('/Cockpit/coreCompany')"
                       @click="toPage('/cockpit/coreCompany', '核心企业', 'CoreCompany')">
            <a-icon type="radar-chart"/>
            核心企业
          </a-menu-item>
          <a-menu-item key="/cockpit/FinancingEco" v-if="twoChange('/cockpit/FinancingEco')"
                       @click="toPage('/cockpit/FinancingEco', '融资电商', 'financialPage')">
            <i class="iconfont">&#xe699;</i> 融资电商
          </a-menu-item>
          <a-menu-item key="/cockpit/AgentIncome" v-if="twoChange('/cockpit/AgentIncome')"
                       @click="toPage('/cockpit/AgentIncome', '代理商收入', 'agentIncome')">
            <i class="iconfont">&#xe699;</i> 代理商收入
          </a-menu-item>
          <!-- <a-menu-item key="/cockpit/AgentIncome" @click="toPage('/cockpit/AgentIncome', '代理商收入')">
            <i class="iconfont">&#xe699;</i>
            代理商收入
          </a-menu-item> -->
        </a-sub-menu>
      </a-menu>
    </div>
    <div class="right">
      <a-dropdown>
        <span class="user">欢迎您！{{ name }}</span>
        <a-menu theme="dark" slot="overlay" style="margin-top:25px;">
          <a-menu-item :disabled="true">
            <span class="quit">{{ companyName }}</span>
          </a-menu-item>
          <a-menu-item @click="openPass()">
            <span class="quit"><a-icon type="form"/>修改密码</span>
          </a-menu-item>
          <a-menu-item @click="quitLogin()">
            <span class="quit"><a-icon type="logout"/>退出登录</span>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
    <!-- tab框 -->
    <div class="tabBar">
      <ul>
        <li v-for="(item, index) in tabBar" :key="item.path" :class="highlightChange(item.path)"
            @click="pushPage(item.path, index, item.componentName)">
          {{ item.name }}
          <a-icon v-if="item.path !== '/home'" @click.stop="onClose(item.path)" type="close-square"/>
        </li>
      </ul>
    </div>
    <!-- 密码框 -->
    <div v-if="modalBool">
      <a-modal title="修改密码" :visible="modalBool" @ok="handleOk" @cancel="handleCancel">
        <a-form :form="form" :label-col="{ span: 4, offset: 1 }" :wrapperCol="{ span: 18, offset: 1 }" :colon="false"
                labelAlign="left">
          <a-form-item label="原密码">
            <a-input v-decorator="[
              'password',
              {
                rules: [{ required: true, message: '请输入原密码' }],
              },
            ]" placeholder="请输入原密码" type="password"/>
          </a-form-item>
          <a-form-item label="新密码">
            <a-input v-decorator="[
              'newPassword',
              {
                rules: [
                  { required: true, message: '请输入新密码' },
                  { validator: checkOldPwd },
                ],
              },
            ]" placeholder="请输入新密码" type="password"/>
          </a-form-item>
          <a-form-item label="确认密码">
            <a-input v-decorator="[
              'checkPwd',
              {
                rules: [
                  { required: true, message: '再次输入密码' },
                  { validator: checkFirstPwd },
                ],
              },
            ]" placeholder="再次输入密码" type="password"/>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>

<script>
import md5 from 'md5'
import {Bus} from '@/utils/bus.js'

export default {
  data() {
    return {
      index_key: [window.location.pathname],
      name: this.$session.getItem('username'),
      tabBar: [],
      arrPath: [],
      arrTwo: [],
      modalBool: false,
      arrNum: undefined,
      companyName: this.$session.getItem('companyName')
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {name: 'modal_form'})
  },
  created() {
    let tabBar = JSON.parse(this.$session.getItem('tabBar'))
    const url = JSON.parse(this.$session.getItem('authPage'))
    let arrOne = url.filter((item) => {
      return item.grade === 1;
    });
    let arrTwo = url.filter((item) => {
      return item.grade === 2;
    });
    let arrPath = arrOne.map((item) => {
      return {
        ...item,
        children: arrayPath(item.id),
      };
    });

    function arrayPath(id) {
      return arrTwo.filter((item) => {
        return item.superId === id;
      });
    }

    // console.log(arrPath.arrOne);
    this.arrTwo = arrTwo;
    this.arrPath = arrPath;
    this.tabBar = tabBar;
  },
  mounted() {
    Bus.$on('send', (obj) => {
      // console.log(obj, 'bus');
      let tabBar = JSON.parse(this.$session.getItem("tabBar"));
      let index = tabBar.findIndex((item) => {
        return item.path === obj.url;
      });
      if (index === -1) {
        tabBar.push({
          path: obj.url,
          name: '发起支付',
        });
        this.arrNum = tabBar.length;
      } else {
        this.arrNum = index + 1;
      }
      this.tabBar = tabBar;
      this.$session.setItem("tabBar", JSON.stringify(tabBar));
      this.$router.push({
        path: obj.url,
        query: {
          type: 'add',
          mchNo: obj.mchNo
        }
      });
    })
  },
  beforeDestroy() {
    Bus.$off()
  },
  methods: {
    //退出登录
    quitLogin() {
      this.$confirm({
        title: "是否退出登录",
        centered: true,
        onOk: () => {
          this.$session.clear();
          this.$router.push("/login");
        },
        onCancel() {
        },
        class: "test",
      });
    },
    //tabBar跳转
    toPage(url, name, componentName) {
      // 存到store,代表要缓存的组件
      this.$store.commit('addKeepAlive', componentName)
      // 存储store
      let tabBar = JSON.parse(this.$session.getItem("tabBar"));
      let index = tabBar.findIndex((item) => {
        return item.path === url;
      });
      if (index === -1) {
        tabBar.push({
          path: url,
          name: name,
          componentName
        });
        this.arrNum = tabBar.length;
      } else {
        this.arrNum = index + 1;
      }
      this.tabBar = tabBar;
      this.$session.setItem("tabBar", JSON.stringify(tabBar));
      this.$router.push(url);
    },
    //tabBar删除
    onClose(url) {
      let tabBar = JSON.parse(this.$session.getItem("tabBar"));
      // 寻找url相同的
      tabBar.forEach(item => {
        if (item.path === url) {
          this.$store.commit('delKeepAliveList', item.componentName)
        }
      })
      let index = tabBar.findIndex((item) => {
        return item.path === url;
      });
      if (index + 1 === this.arrNum) {
        let path = tabBar[index - 1].path;
        this.$router.replace(path);
        tabBar.splice(index, 1);
        this.tabBar = tabBar;
        this.arrNum = index;
        this.$session.setItem("tabBar", JSON.stringify(tabBar));
      } else {
        tabBar.splice(index, 1);
        this.tabBar = tabBar;
        this.arrNum = tabBar.length;
        this.$session.setItem("tabBar", JSON.stringify(tabBar));
      }
      this.index_key = [window.location.pathname];
    },
    //tabBar高亮
    highlightChange(path) {
      let bool = window.location.pathname === path;
      return {active: bool};
    },
    pushPage(url, index, componentName) {
      // 存到store,代表要缓存的组件
      this.$store.commit('addKeepAlive', componentName)
      this.index_key = [url];
      this.highlightChange(url);
      this.arrNum = index + 1;
      if (url === '/orders/PaymentOrders/AddPaymentOrders') {
        let orderInfo = this.$store.state.orderData
        this.$router.push({
          path: url,
          query: {
            type: 'add',
            mchNo: orderInfo.mchNo
          }
        });


      } else {
        this.$router.push(url);

      }

    },
    oneChange(path) {
      const arr = this.arrPath.filter((item) => {
        return item.path === path;
      });

      if (arr.length !== 0) {
        return arr[0].children.length !== 0;
      } else {
        return false;
      }
    },
    twoChange(path) {
      return this.arrTwo.some((item) => {
        // console.log(item, 'item');
        return item.path === path;
      });
    },
    defaultMenu(menu) {
      console.info(menu, 111)
    },
    //  密码框
    openPass() {
      this.modalBool = true
    },
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          const {password, newPassword} = values
          this.$axios
              .apiGet('/user/modifyPassword', {
                password: md5(password),
                newPassword: md5(newPassword),
              })
              .then((res) => {
                if (res.code !== 200) return this.$message.error(res.info)
                this.$message.success(res.info)
                this.modalBool = false
              })
        }
      })
    },
    handleCancel() {
      this.modalBool = false
    },
    checkOldPwd(rule, value, callback) {
      const form = this.form
      const reg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{6,16}$/
      if (value && !value.match(reg)) {
        callback(
            '请输入6-16位由大、小写字母、数字(0-9)、特殊字符至少三种进行组合密码'
        )
      }
      if (value && value === form.getFieldValue('password')) {
        callback('新密码与原密码一致！')
      } else {
        callback()
      }
    },
    checkFirstPwd(rule, value, callback) {
      const form = this.form
      if (value && value !== form.getFieldValue('checkPwd')) {
        callback('两次输入密码不一致！')
      } else {
        callback()
      }
    },
  },
}
</script>

<style scoped lang="less">
#header {
  position: relative;

  .middle {
    padding-left: 80px;
    padding-right: 186px;
    background: #001529;
  }

  .el-menu--horizontal > .el-menu-item.is-active {
    background-color: #78909c !important;
  }

  .ant-menu-submenu-selected{
    background-color: #188ffd !important;
  }

  .ant-menu-submenu-active{
    color: #fff !important;
  }

  .title {
    width: 100px;
    line-height: 56px;
    letter-spacing: 8px;
    font-size: 18px;
    color: #fafafa;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
  }

  .right {
    min-width: 186px;
    line-height: 56px;
    padding-right: 10px;
    text-align: center;
    position: absolute;
    height: 56px;
    top: 0;
    right: 0;
    cursor: pointer;
    color: #fafafa;

    .user {
      margin-right: 6px;
    }
  }

  .tabBar {
    ul {
      display: flex;
      width: 100%;
      padding: 10px 20px 0 20px;
      flex-wrap: wrap;
      border-bottom: 1px solid #bbbbbb;
      background: #ffffff;
      margin: 0 0 2px 0;
    }

    li:first-child {
      padding-right: 12px;
    }

    li {
      display: inline-block;
      background-color: #ffffff;
      list-style-type: none;
      margin: 6px 0 0 4px;
      cursor: pointer;
      padding: 4px 4px 2px 15px;
      border-radius: 6px 6px 0 0;
      border: 0.8px solid #eeeeee;
      border-bottom: 0;
      color: #666;
      height: 100%;


      span {
        margin-left: 2px;
      }

      i {
        visibility: hidden;
        position: relative;
        top: -5px;
        left: 2px;
        font-size: 0.8em;
        color: #6a6a6a;
      }

      i:hover {
        visibility: visible;
        color: red !important;
      }
    }

    li:hover {
      background-color: #f9f9f9;
      border-color: #cccccc;

      i {
        visibility: visible;
        font-weight: bold;
      }
    }

    li.active {
      background-color: #f2f2f2;
      border-bottom: 1px solid #f2f2f2;
      border-left-color: #bbbbbb;
      border-right-color: #bbbbbb;
      border-top-color: #bbbbbb;
      margin-bottom: -1px;

      i {
        color: #188efb;
        visibility: visible;
      }
    }
  }
}

</style>
