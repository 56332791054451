import { createApp } from '../../main-factory';
import routes from './routes';  // 物流管理路由
import store from '../../store/common';    // 电商状态管理

const app = createApp({
    routes,
    store,
    titleSuffix: '物流管理平台',
    appName: 'logistics',
    loginPath: '/login',
    basePath: '/logistics',  // 应用路径
    App: require('../logistics/App.vue').default,  // 根组件
});

app.$mount('#app');  // 挂载到 #logistics-app
