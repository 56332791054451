<template>
  <div id="layout" v-if='$route.path !== "/login"'>
    <header-view></header-view>
    <div class="layout_middle">
      <keep-alive :include="keepAliveArr">
        <router-view></router-view>
      </keep-alive>
    </div>
  </div>
  <div v-else>
    <router-view></router-view>
  </div>
</template>

<script>
import Header from "@/apps/logistics/layout/Header.vue"
export default {
  components: {
    'header-view': Header,
  },
  data() {
    return {
      index_key: window.location.pathname,
      // keepAliveArr: this.$store.state.keepAliveArr
    }
  },
  watch: {
    // $route: {//监听路由变化，实时更新本地的和vuex中的保持一致
    //   handler: function () {
    //     this.keepAliveArr = this.$store.state.keepAliveArr
    //   }
    // }
  },
  computed: {
    keepAliveArr() {
      return this.$store.state.keepAliveArr
    }
  },
  methods: {
    quitLogin() {
      this.$session.clear()
      this.$router.push('/login')
    }
  }

}
</script>

<style scoped lang = "less">
#layout{
  min-width: 800px;
}
</style>