<template>
  <a-config-provider :locale="locale">
    <layout-view></layout-view>
  </a-config-provider>
</template>
<script>
import Layout from './layout/Layout.vue'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { mapState } from 'vuex'
import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
moment.locale('zh-cn');
export default {
  name: 'app',
  components: {
    'layout-view': Layout,
  },
  data() {
    return {
      locale: zhCN,
    }
  },
  computed: {
    ...mapState(['example']),
  },
  beforeUpdate() {
    // console.log(this.$store.state.example)
    // console.log(this.$store.commit('example'),this.example)
  },
}
</script>

<style lang="less">
@import '../../assets/iconfont/iconfont.css';

.ant-modal-confirm-body-wrapper {
  svg {
    width: 20px;
    height: 20px;
  }
}

// 为了解决ui组件库更新丢失乱码问题(分页组件省略号图标乱码)
.ant-pagination-item-ellipsis {
  width: 20px;
  overflow: hidden;

  &::before {
    content: '...' !important;
  }
}

// 为了解决ui组件库更新丢失乱码问题(头部组件省略号图标乱码)

.ant-menu-overflowed-submenu {
  .ant-menu-submenu-title {
    width: 5px;
    overflow: hidden;

    span {
      &::before {
        content: '...' !important;
        margin-right: 11px;
      }
    }
  }
}
.ant-menu-submenu .ant-menu-sub .ant-menu-item:hover {
  color: #fafafa;
}

#layout {
  background-color: #f2f2f2;
  min-height: 600px;
  padding-bottom: 5px;
}

* {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

body {
  background: #fafafa;
}

#login {
  .ant-form-item {
    margin-bottom: 12px;
  }
}

.ant-btn {
  margin-right: 12px;
}
.ant-btn-group {
  margin-right: 12px;

  .ant-btn {
    margin-right: 0;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.ant-input {
  resize: none;
}
.ant-table-tbody> tr.ant-table-row-selected td {
  background-color: rgba(145, 213, 255, 0.4) !important;
}
.msg-box-wide{
  width: 1000px !important;
}
</style>
